import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  id: "vsscript_144563_128035"
};
import { onMounted, onBeforeUnmount, reactive } from "vue";
export default {
  setup: function setup(__props) {
    window.vs_embed_width = "100%";
    var state = reactive({
      loaded: false,
      scriptElement: null
    });
    /**
     * Loads the ImpactRadius tag into the DOM, then calls identify() if a user is logged in.
     */

    var loadScript = function loadScript() {
      var script = document.createElement('script');
      script.async = true;
      script.src = 'https://app.viralsweep.com/vsa-embed-6d38f1-144563.js?sid=144563_128035';
      document.head.appendChild(script);
      script.addEventListener('load', function () {
        state.loaded = true;
      });
      state.scriptElement = script;
    };
    /**
     * Remove the script element from the head when the component is unmounted
     */


    var unloadScript = function unloadScript() {
      if (state.scriptElement && state.scriptElement.parentNode) {
        state.scriptElement.parentNode.removeChild(state.scriptElement);
      }
    };
    /**
     * Removes the gallery element when component unmounts
     */


    var removeGallery = function removeGallery() {
      var iframeElement = document.getElementById('vs_embed_frame_vs_144563_144563_128035');

      if (iframeElement !== null && iframeElement !== void 0 && iframeElement.parentElement) {
        iframeElement.parentElement.remove();
      }
    };
    /**
     * Removes the window.vs_embed_width property
     */


    var removeVsEmbedWidth = function removeVsEmbedWidth() {
      delete window.vs_embed_width;
    };

    onMounted(function () {
      loadScript();
    });
    onBeforeUnmount(function () {
      unloadScript();
      removeGallery();
      removeVsEmbedWidth();
    });
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("div", _hoisted_1);
    };
  }
};